






































































































































































import Vue from 'vue'
import { DispatchingCreditState } from '../../../../store/models/DispatchingCredit'
import { DispatchingCredit } from '../../../../store/models/DispatchingCredit'
import LabelStatus from '../../../../components/LabelStatus.vue'
import api from '../../../../api'
import Dispatching from '../../../../store/models/Dispatching'
import moment from 'moment'
//import LinearProgressBar from '../../../../components/LinearProgressBar.vue'

interface ComponentData {
  loading: boolean
  downloadLoading: boolean
  hideSearchInput: boolean
  showRapportModal: boolean
  refreshTimer: number | null
  columns: Record<string, any>[]
  selectedField: string
  downloadElements: Record<string, any>[]
  search: string
}

export default Vue.extend({
  components: {
    LabelStatus
    //LinearProgressBar
  },
  data(): ComponentData {
    return {
      hideSearchInput: true,
      showRapportModal: false,
      search: '',
      loading: false,
      downloadLoading: false,
      refreshTimer: null,
      selectedField: DispatchingCreditState.ALL,
      downloadElements: [
        {
          title: 'Rapport complet',
          value: DispatchingCreditState.ALL
        },
        {
          title: 'Rapport succès',
          value: DispatchingCreditState.SUCCEEDED
        },
        {
          title: 'Rapport echec',
          value: DispatchingCreditState.FAILED
        },
        {
          title: 'Rapport en attente',
          value: DispatchingCreditState.PENDING
        }
      ],
      columns: [
        {
          label: 'N° de dossier',
          field: 'reference',
          sortable: false
        },
        {
          label: 'NPI',
          field: 'beneficiary.npi',
          sortable: false
        },
        {
          label: 'Nom',
          field: 'beneficiary.name',
          sortable: false
        },
        {
          label: 'Sexe',
          field: 'beneficiary.gender',
          sortable: false
        },
        {
          label: 'N° MoMo',
          field: 'beneficiary.phone',
          sortable: false
        },
        {
          label: 'Montant à',
          field: 'amount',
          sortable: false
        },
        {
          label: 'Agence',
          field: 'agency',
          sortable: false
        },
        {
          label: 'Statut',
          field: 'state',
          sortable: false
        }
      ]
    }
  },

  computed: {
    fieldTitles() {
      return [
        {
          title: 'Tout',
          value: DispatchingCreditState.ALL,
          total: DispatchingCredit.query().count()
        },
        {
          title: 'Succès',
          value: DispatchingCreditState.SUCCEEDED,
          total: DispatchingCredit.query()
            .where('state', (value: string) => {
              return value == DispatchingCreditState.SUCCEEDED
            })
            .count()
        },
        {
          title: 'En attente',
          value: DispatchingCreditState.PENDING,
          total: DispatchingCredit.query()
            .where('state', (value: string) => {
              return value == DispatchingCreditState.PENDING
            })
            .count()
        },
        {
          title: 'Échec',
          value: DispatchingCreditState.FAILED,
          total: DispatchingCredit.query()
            .where('state', (value: string) => {
              return value == DispatchingCreditState.FAILED
            })
            .count()
        }
      ]
    },

    dispatchingId(): string {
      return this.$route.params.dispatchingId
    },
    totalDispatchingCreditsFailed(): number {
      return DispatchingCredit.query()
        .where('dispatchingId', this.dispatchingId)
        .where('state', (value: string) => {
          return value == DispatchingCreditState.FAILED
        })
        .count()
    },
    dispatchingCredits(): DispatchingCredit[] {
      let result: DispatchingCredit[]
      if (this.selectedField == DispatchingCreditState.ALL)
        result = DispatchingCredit.query()
          .where('dispatchingId', this.dispatchingId)
          .where('beneficiary', (value: any) => {
            console.log('ben', value)
            return (
              value.name?.toUpperCase().includes(this.search.toUpperCase()) ||
              value.phone?.includes(this.search)
            )
          })
          .get()
      else {
        console.log('eeeeeeeeeeee', this.selectedField)

        result = DispatchingCredit.query()
          .where('dispatchingId', this.dispatchingId)
          .where('state', (value: string) => {
            return value == this.selectedField
          })
          .where('beneficiary', (value: any) => {
            console.log(value)
            return (
              value.name?.toUpperCase().includes(this.search.toUpperCase()) ||
              value.phone?.includes(this.search)
            )
          })
          .get()
      }

      console.log(result)

      return result
    },
    dispatching(): any {
      return Dispatching.query()
        .with('creditLine')
        .find(this.dispatchingId)
    },
    showProgress(): number {
      console.log('dispatching', this.dispatching)
      if (this.dispatching.relaunchCreditAt) {
        if (
          moment
            .duration(
              moment(Date.now()).diff(moment(this.dispatching.relaunchCreditAt))
            )
            .asHours() <= 1
        )
          return 1
        else return 2
      } else if (this.dispatching.validatedAt) {
        if (
          moment
            .duration(
              moment(Date.now()).diff(moment(this.dispatching.validatedAt))
            )
            .asHours() <= 1
        )
          return 1
        return 2
      } else return 0
    },
    pourcent(): number {
      const countSuccess = DispatchingCredit.query()
        .where('dispatchingId', this.dispatchingId)
        .where('state', (value: string) => {
          return value == DispatchingCreditState.SUCCEEDED
        })
        .count()

      const total = DispatchingCredit.query()
        .where('dispatchingId', this.dispatchingId)
        .count()

      if (total != 0) return Math.round((countSuccess / total) * 100)
      return 0
    }
  },

  methods: {
    fetchDispatchingCredit(
      dispatchingId: string,
      status = DispatchingCreditState.ALL
    ) {
      console.log('This is dispatching id', dispatchingId)
      DispatchingCredit.api().fetchDispatchingCredit(dispatchingId, status)
    },

    searchIconClick() {
      this.hideSearchInput = !this.hideSearchInput
      /*if(this.hideSearchInput == false)
            {
                 console.log("salut", this.$refs);
                 this.$refs.search.$el.focus()
            }*/
    },

    changeStatus(status: string): any {
      this.selectedField = status
    },

    downloadReport(status: DispatchingCreditState) {
      console.log(status)
      this.downloadLoading = true
      let fileName = 'Rapport'

      switch (status) {
        case DispatchingCreditState.ALL:
          fileName = 'Rapport de dispatching complet'
          break
        case DispatchingCreditState.SUCCEEDED:
          fileName = 'Rapport de dispatching réussi'
          break
        case DispatchingCreditState.FAILED:
          fileName = 'Rapport de dispatching échoué'
          break
        case DispatchingCreditState.PENDING:
          fileName = 'Rapport de dispatching en attente'
          break
      }
      api
        .download(
          `/dispatching-credits/download/dispatching-report/${this.dispatchingId}?status=${status}`,
          {},
          `${fileName}.xlsx`
        )
        .then(() => {
          this.downloadLoading = false
          console.log('Super')
        })
        .catch(() => {
          this.downloadLoading = false
        })
    },

    relaunch() {
      DispatchingCredit.api().relaunch(this.dispatchingId)
    }
  },

  mounted() {
    DispatchingCredit.deleteAll()
    Dispatching.api().getOne(this.dispatchingId),
      this.fetchDispatchingCredit(
        this.dispatchingId,
        DispatchingCreditState.ALL
      )

    if (!this.refreshTimer) {
      // TODO: maybe websocket
      this.refreshTimer = setInterval(() => {
        this.fetchDispatchingCredit(
          this.dispatchingId,
          DispatchingCreditState.ALL
        )
      }, 5000)
    }
  },

  beforeDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  },

  created() {
    Dispatching.api().getOne(this.dispatchingId)
    this.fetchDispatchingCredit(this.dispatchingId, DispatchingCreditState.ALL)
  }
})
