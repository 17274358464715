


























import Vue from 'vue'
export default Vue.extend({
  props: {
    primaryColor: {
      type: String
    },

    secondaryColor: {
      type: String
    },

    circleColor: {
      type: String
    }
  }
})
